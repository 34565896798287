import { BaseModel } from '@core/base-model';

export enum AuthAttributeName {
  id = 'sub',
  email = 'email',
  email_verified = 'email_verified',
  phone_number = 'phone_number',
  phone_number_verified = 'phone_number_verified',
}

export interface AuthGetQuery {
  id: string;
  email: string;
  isEmailVerified: boolean;
  phone?: string;
  isPhoneVerified?: boolean;
}

export class AuthGetModel extends BaseModel<AuthGetQuery> {
  id: string;
  email: string;
  isEmailVerified: boolean;
  phone?: string;
  isPhoneVerified?: boolean;

  constructor(data: AuthGetQuery) {
    super();

    this.update(data);
  }

  get asJson(): AuthGetQuery {
    return {
      id: this.id,
      email: this.email,
      isEmailVerified: this.isEmailVerified,
      phone: this.phone,
      isPhoneVerified: this.isPhoneVerified,
    };
  }
}
