import { BaseModel } from '@core/base-model';

export interface AuthorizationRequestQuery {
  deviceCode: string;
  userCode: string;
  expiresIn: string;
}

export class AuthorizationRequestModel extends BaseModel<AuthorizationRequestQuery> {
  private deviceCode: string;
  private userCode: string;
  private expiresIn: string;

  get isExpired() {
    return Date.parse(this.expiresIn) < Date.now();
  }

  constructor(data: AuthorizationRequestQuery) {
    super();
    this.update(data);
  }

  get asJson(): AuthorizationRequestQuery {
    return {
      deviceCode: this.deviceCode,
      userCode: this.userCode,
      expiresIn: this.expiresIn,
    };
  }
}
