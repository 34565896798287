import { Currency } from '@shared/models/wallets/currency';

export enum PaymentType {
  card = 'card',
  deposit = 'deposit',
  addCard = 'addCard',
}

export enum MultiMetalPaymentType {
  card = 'Card',
  deposit = 'Deposit',
}

export interface PaymentMethod {
  label: string;
  value: string;
  type: PaymentType;
  currency: Currency;
}
