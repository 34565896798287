import {
  SmartCoinOrderStatusExternal,
  SmartCoinOrderStatusInternal,
} from '@shared/types/smart-coin-order-status';

export const smartCoinOrderStatusPipe = (
  internalStatus: SmartCoinOrderStatusInternal,
): SmartCoinOrderStatusExternal => {
  let externalStatus: SmartCoinOrderStatusExternal;
  switch (internalStatus) {
    case SmartCoinOrderStatusInternal.PENDING:
    case SmartCoinOrderStatusInternal.METAL_TRADED:
    case SmartCoinOrderStatusInternal.METAL_EXCHANGED:
    case SmartCoinOrderStatusInternal.OVERDRAFT_TAKEN:
    case SmartCoinOrderStatusInternal.PAID:
    case SmartCoinOrderStatusInternal.SMART_COIN_DEPOSITED:
    case SmartCoinOrderStatusInternal.OVERDRAFT_PAID_BACK:
    case SmartCoinOrderStatusInternal.OVERDRAFT_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.PAYMENT_RESERVED:
    case SmartCoinOrderStatusInternal.CONFIRMATION_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.PAYMENT_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.DEPOSIT_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.FAILED:
    case SmartCoinOrderStatusInternal.METAL_MINTED:
    case SmartCoinOrderStatusInternal.SMART_COIN_BLOCKCHAIN_TRANSFERRED:
    case SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_RESERVED:
    case SmartCoinOrderStatusInternal.METAL_TRADE_QUANTITY_CALCULATED:
    case SmartCoinOrderStatusInternal.METAL_EXCHANGE_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.SMART_COIN_BLOCKCHAIN_TRANSFER_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_CONFIRMATION_IN_PROGRESS:
    case SmartCoinOrderStatusInternal.SMART_COIN_TRANSFERRED:
      externalStatus = SmartCoinOrderStatusExternal.IN_PROGRESS;
      break;
    case SmartCoinOrderStatusInternal.EXECUTED:
      externalStatus = SmartCoinOrderStatusExternal.EXECUTED;
      break;
    case SmartCoinOrderStatusInternal.CANCELED:
      externalStatus = SmartCoinOrderStatusExternal.CANCELED;
      break;
    case SmartCoinOrderStatusInternal.DEPOSIT_REQUIRES_ACTION:
      externalStatus = SmartCoinOrderStatusExternal.SECURE_3D;
      break;
    case SmartCoinOrderStatusInternal.NEW:
      externalStatus = SmartCoinOrderStatusExternal.NEW;
      break;
    default:
      externalStatus = SmartCoinOrderStatusExternal.IN_PROGRESS;
  }
  return externalStatus;
};

export const smartCoinOrderInternalStatusPipe = (
  internalStatus: SmartCoinOrderStatusExternal,
): Array<SmartCoinOrderStatusInternal> => {
  let internalStatuses: Array<SmartCoinOrderStatusInternal>;
  switch (internalStatus) {
    case SmartCoinOrderStatusExternal.IN_PROGRESS:
      internalStatuses = [
        SmartCoinOrderStatusInternal.METAL_TRADED,
        SmartCoinOrderStatusInternal.METAL_EXCHANGED,
        SmartCoinOrderStatusInternal.OVERDRAFT_TAKEN,
        SmartCoinOrderStatusInternal.PAID,
        SmartCoinOrderStatusInternal.SMART_COIN_DEPOSITED,
        SmartCoinOrderStatusInternal.OVERDRAFT_PAID_BACK,
        SmartCoinOrderStatusInternal.PAYMENT_RESERVED,
        SmartCoinOrderStatusInternal.PAYMENT_IN_PROGRESS,
        SmartCoinOrderStatusInternal.OVERDRAFT_IN_PROGRESS,
        SmartCoinOrderStatusInternal.DEPOSIT_IN_PROGRESS,
        SmartCoinOrderStatusInternal.FAILED,
        SmartCoinOrderStatusInternal.METAL_MINTED,
        SmartCoinOrderStatusInternal.SMART_COIN_BLOCKCHAIN_TRANSFERRED,
        SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_RESERVED,
        SmartCoinOrderStatusInternal.METAL_EXCHANGE_IN_PROGRESS,
        SmartCoinOrderStatusInternal.SMART_COIN_BLOCKCHAIN_TRANSFER_IN_PROGRESS,
        SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_CONFIRMATION_IN_PROGRESS,
        SmartCoinOrderStatusInternal.SMART_COIN_TRANSFER_IN_PROGRESS,
        SmartCoinOrderStatusInternal.SMART_COIN_TRANSFERRED,
      ];
      break;
    case SmartCoinOrderStatusExternal.EXECUTED:
      internalStatuses = [SmartCoinOrderStatusInternal.EXECUTED];
      break;
    case SmartCoinOrderStatusExternal.CANCELED:
      internalStatuses = [SmartCoinOrderStatusInternal.CANCELED];
      break;
    case SmartCoinOrderStatusExternal.SECURE_3D:
      internalStatuses = [SmartCoinOrderStatusInternal.DEPOSIT_REQUIRES_ACTION];
      break;
    case SmartCoinOrderStatusExternal.NEW:
      internalStatuses = [SmartCoinOrderStatusInternal.NEW];
      break;
  }
  return internalStatuses;
};
