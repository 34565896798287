import { BaseModel } from '@core/base-model';
import { OrderStatus } from '@shared/models/orders/order-status';
import { Currency } from '@shared/models/wallets/currency';

export interface OrderListQuery {
  id: string;
  userId: string;
  orderDate: string;
  plannedFulfillmentDate: string;
  actualFulfillmentDate: string;
  paymentMethod: string | null;
  metalWeight: number;
  metalSpotPrice: number;
  price: number;
  currency: Currency;
  status: OrderStatus;
  isPurchaser: boolean;
  isFullOwner: boolean;
  mintedWeight: number;
  unMintedWeight: number;
}

export class OrderListModel extends BaseModel<OrderListQuery> {
  private id: string;
  private userId: string;
  private orderDate: string;
  private plannedFulfillmentDate: string;
  private actualFulfillmentDate: string;
  private paymentMethod: string | null;
  private metalWeight: number;
  private metalSpotPrice: number;
  private price: number;
  private currency: Currency;
  private status: OrderStatus;
  private isPurchaser: boolean;
  private isFullOwner: boolean;
  private mintedWeight: number;
  private unMintedWeight: number;

  constructor(data: OrderListQuery) {
    super();
    this.update(data);
  }

  get asJson(): OrderListQuery {
    return {
      id: this.id,
      userId: this.userId,
      orderDate: this.orderDate,
      plannedFulfillmentDate: this.plannedFulfillmentDate,
      actualFulfillmentDate: this.actualFulfillmentDate,
      paymentMethod: this.paymentMethod,
      metalWeight: this.metalWeight,
      metalSpotPrice: this.metalSpotPrice,
      price: this.price,
      currency: this.currency,
      status: this.status,
      isPurchaser: this.isPurchaser,
      isFullOwner: this.isFullOwner,
      mintedWeight: this.mintedWeight,
      unMintedWeight: this.unMintedWeight,
    };
  }
}
