import { BaseModel } from '@core/base-model';
import { OrderStatus } from '@shared/models/orders/order-status';

export type ResponsePaymentResult = {
  paymentIntendId?: string;
  clientSecret?: string;
  url?: string;
  reference?: string;
  jwt?: string;
};

export interface PaymentResult {
  transactionId: string;
  providerType: string;
  requiresFurtherAction: boolean;
  isSucceeded: boolean;
  code: string;
  declineCode: string;
  response: ResponsePaymentResult;
}

export interface OrderStatusQuery {
  status: OrderStatus;
  paymentResult: PaymentResult;
  declineCode: string | null;
  declineMessage: string | null;
}

export class OrderStatusModel extends BaseModel<OrderStatusQuery> {
  public status: OrderStatus;
  public paymentResult: PaymentResult;
  private declineCode: string | null;
  public declineMessage: string | null;

  constructor(data: OrderStatusQuery) {
    super();
    this.update(data);
  }

  get asJson(): OrderStatusQuery {
    return {
      status: this.status,
      paymentResult: this.paymentResult,
      declineCode: this.declineCode,
      declineMessage: this.declineMessage,
    };
  }
}
