import { BaseModel } from '@core/base-model';

export interface TermsDocumentQuery {
  id: string;
  title: string;
  lastUpdateDate: string;
  url: string;
}

export class TermsDocumentModel extends BaseModel<TermsDocumentQuery> {
  id: string;
  title: string;
  lastUpdateDate: string;
  url: string;

  constructor(data: TermsDocumentQuery) {
    super();
    this.update(data);
  }

  get asJson(): TermsDocumentQuery {
    return {
      id: this.id,
      title: this.title,
      lastUpdateDate: this.lastUpdateDate,
      url: this.url,
    };
  }
}
