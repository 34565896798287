import { BaseModel } from '@core/base-model';

export interface AuthSessionQuery {
  idToken: string;
  accessToken: string;
  expiresIn: string;
  tokenType: string;
  refreshToken: string;
}

export class AuthSessionModel extends BaseModel<AuthSessionQuery> {
  private idToken: string;
  private accessToken: string;
  private expiresIn: string;
  private tokenType: string;
  private refreshToken: string;

  constructor(data: AuthSessionQuery) {
    super();
    this.update(data);
  }

  get asJson(): AuthSessionQuery {
    return {
      idToken: this.idToken,
      accessToken: this.accessToken,
      expiresIn: this.expiresIn,
      tokenType: this.tokenType,
      refreshToken: this.refreshToken,
    };
  }
}
