export enum UserStatus {
  PreRegistered = 'PreRegistered',
  PinAdded = 'PinAdded',
  PhoneNumberAdded = 'PhoneNumberAdded',
  TypeOfAccountSet = 'TypeOfAccountSet',
  DocumentsApproved = 'DocumentsApproved',
  SystemNameAdded = 'SystemNameAdded',
  NotVerified = 'NotVerified',
  UnderReview = 'UnderReview',
  Verified = 'Verified',
  Declined = 'Declined',
  Blocked = 'Blocked',
  DeletionRequested = 'DeletionRequested',
  Deleted = 'Deleted',
}
