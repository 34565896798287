import { BaseModel } from '@core/base-model';

export interface GetRateDTO {
  product: string;
  currency: string;
  lastUpdatedDate: string;
  price: number;
  priceInSystemBaseCurrency: number;
  ouncePrice: number;
  ouncePriceInSystemBaseCurrency: number;
  kgPrice: number;
  kgPriceInSystemBaseCurrency: number;
  isSpot: boolean;
  isEmergencyEnabled: boolean;
}

export class GetRateModel extends BaseModel<GetRateDTO> {
  private product: string;
  private currency: string;
  private lastUpdatedDate: string;
  private price: number;
  private priceInSystemBaseCurrency: number;
  private ouncePrice: number;
  private ouncePriceInSystemBaseCurrency: number;
  private kgPrice: number;
  private kgPriceInSystemBaseCurrency: number;
  private isSpot: boolean;
  private isEmergencyEnabled: boolean;

  constructor(data: GetRateDTO) {
    super();
    this.update(data);
  }

  get asJson(): GetRateDTO {
    return {
      currency: this.currency,
      lastUpdatedDate: this.lastUpdatedDate,
      product: this.product,
      price: this.price,
      priceInSystemBaseCurrency: this.priceInSystemBaseCurrency,
      ouncePrice: this.ouncePrice,
      ouncePriceInSystemBaseCurrency: this.ouncePriceInSystemBaseCurrency,
      kgPrice: this.kgPrice,
      kgPriceInSystemBaseCurrency: this.kgPriceInSystemBaseCurrency,
      isSpot: this.isSpot,
      isEmergencyEnabled: this.isEmergencyEnabled,
    };
  }
}
