import { t } from '@lingui/macro';
import { TransactionType } from '@shared/models/transactions/transaction-type';

export const transactionTypePipe = (type: TransactionType): string => {
  switch (type) {
    case TransactionType.PURCHASED:
      return t`Purchased`;
    case TransactionType.SOLD:
      return t`Sold`;
    case TransactionType.SENT:
      return t`Sent`;
    case TransactionType.RECEIVED:
      return t`Received`;
    case TransactionType.DEPOSIT:
      return t`Deposit`;
    case TransactionType.SPENT:
      return t`Spent`;
    case TransactionType.EARNED:
      return t`Earned`;
    case TransactionType.WITHDRAWAL:
      return t`Withdrawn`;
    default:
      return '';
  }
};
