import { BaseModel } from '@core/base-model';
import { Currency } from '@shared/models/wallets/currency';

export enum ProductType {
  FIAT_COIN = 'FiatCoin',
  SMART_COIN = 'SmartCoin',
}

export interface ProductQuery {
  id: string;
  name: string;
  type: string;
  baseCurrency: string;
}

export class ProductModel extends BaseModel<ProductQuery> {
  private id: string;
  private name: string;
  private type: string;
  private baseCurrency: string;
  private viewPriority: number;

  constructor(data: ProductQuery) {
    super();
    this.update(data);
    this.changeName();
    this.setViewPriorityByBaseCurrency(data.baseCurrency);
  }

  changeName() {
    switch (this.baseCurrency.toUpperCase()) {
      case 'GOLD':
        this.name = Currency.GSC;
        break;
      case 'USD':
        this.name = Currency.USDT;
        break;
      case 'AUD':
        this.name = Currency.AUDT;
        break;
      default:
        break;
    }
  }

  setViewPriorityByBaseCurrency(baseCurrency: string) {
    switch (baseCurrency.toLowerCase()) {
      case 'gold':
        this.viewPriority = 1;
        break;
      case 'aud':
        this.viewPriority = 2;
        break;
      case 'usd':
        this.viewPriority = 3;
        break;
      default:
        this.viewPriority = 4;
    }
  }

  get asJson(): ProductQuery & { viewPriority: number } {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      baseCurrency: this.baseCurrency,
      viewPriority: this.viewPriority,
    };
  }
}
