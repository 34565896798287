import { BaseModel } from '@core/base-model';

export interface RequisitesListQuery {
  iban: string;
  name: string;
  recipientAddress: string;
  recipientCountry: string;
  recipientName: string;
  swiftCode: string;
}

export class RequisitesListModel extends BaseModel<RequisitesListQuery> {
  private iban: string;
  private name: string;
  private recipientAddress: string;
  private recipientCountry: string;
  private recipientName: string;
  private swiftCode: string;

  constructor(data: RequisitesListQuery) {
    super();
    this.update(data);
  }

  get asJson(): RequisitesListQuery {
    return {
      iban: this.iban,
      name: this.name,
      recipientAddress: this.recipientAddress,
      recipientCountry: this.recipientCountry,
      recipientName: this.recipientName,
      swiftCode: this.swiftCode,
    };
  }
}
