import { BaseModel } from '@core/base-model';

export interface currentSpotPricesDTO {
  gr: number;
  oz: number;
  kg: number;
}

export interface SpotPriceHistoryItemDTO {
  id: string;
  date: string;
  smartCoin: string;
  purchasePrice: number;
  salePrice: number;
  fiatCurrency: string;
  period: string;
}

export interface SpotPriceHistoryDataResponseDTO {
  currentSpotPrices: currentSpotPricesDTO;
  currentSpotPricesInBaseCurrency: currentSpotPricesDTO;
  historicData: Array<SpotPriceHistoryItemDTO>;
}

export class SpotPriceHistoryDataResponseModel extends BaseModel<SpotPriceHistoryDataResponseDTO> {
  private currentSpotPrices: currentSpotPricesDTO;
  private currentSpotPricesInBaseCurrency: currentSpotPricesDTO;
  private historicData: Array<SpotPriceHistoryItemDTO>;

  constructor(data: SpotPriceHistoryDataResponseDTO) {
    super();
    this.update(data);
  }

  get asJson(): SpotPriceHistoryDataResponseDTO {
    return {
      currentSpotPrices: this.currentSpotPrices,
      currentSpotPricesInBaseCurrency: this.currentSpotPricesInBaseCurrency,
      historicData: this.historicData,
    };
  }
}
