import { BaseModel } from '@core/base-model';

export interface ProductPricesDTO {
  product: string;
  currency: string;
  marketPrice: number;
  purchaseTradePrice: number;
  sellTradePrice: number;
  lastUpdatedDate: string;
  dailyChangeInPercent: number;
  isSpot: boolean;
}

export class ProductPricesModel extends BaseModel<ProductPricesDTO> {
  private product: string;
  private currency: string;
  private marketPrice: number;
  private purchaseTradePrice: number;
  private sellTradePrice: number;
  private lastUpdatedDate: string;
  private dailyChangeInPercent: number;
  private isSpot: boolean;

  constructor(data: ProductPricesDTO) {
    super();
    this.update(data);
  }

  get asJson(): ProductPricesDTO {
    return {
      product: this.product,
      currency: this.currency,
      marketPrice: this.marketPrice,
      purchaseTradePrice: this.purchaseTradePrice,
      sellTradePrice: this.sellTradePrice,
      lastUpdatedDate: this.lastUpdatedDate,
      dailyChangeInPercent: this.dailyChangeInPercent,
      isSpot: this.isSpot,
    };
  }
}
