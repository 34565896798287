import { BaseModel } from '@core/base-model';

export interface WalletsListQuery {
  balanceInBaseFiatCurrency: number;
  balanceInFiatCurrency: number;
  id: string;
  currency: string;
  balance: number;
  upperLimit: number;
  lowerLimit: number;
}

export class WalletsListModel extends BaseModel<WalletsListQuery> {
  private id: string;
  private currency: string;
  private balance: number;
  private balanceInBaseFiatCurrency: number;
  private balanceInFiatCurrency: number;
  private upperLimit: number;
  private lowerLimit: number;

  constructor(data: WalletsListQuery) {
    super();

    this.update(data);
  }

  get asJson(): WalletsListQuery {
    return {
      id: this.id,
      currency: this.currency,
      balance: this.balance,
      balanceInBaseFiatCurrency: this.balanceInBaseFiatCurrency,
      balanceInFiatCurrency: this.balanceInFiatCurrency,
      upperLimit: this.upperLimit,
      lowerLimit: this.lowerLimit,
    };
  }
}
