import { t } from '@lingui/macro';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';

export const transactionStatusePipe = (status: TransactionStatus): string => {
  switch (status) {
    case TransactionStatus.RequiresAction:
    case TransactionStatus.InProgress:
      return t`In progress`;
    case TransactionStatus.Failed:
      return t`Canceled`;
    case TransactionStatus.Completed:
      return t`Successful`;
    default:
      return '';
  }
};

export const transactionStatusToExternalStatus = (
  transactionStatus: TransactionStatus,
): SmartCoinOrderStatusExternal => {
  let externalStatus: SmartCoinOrderStatusExternal;
  switch (transactionStatus) {
    case TransactionStatus.InProgress:
    case TransactionStatus.RequiresAction:
      externalStatus = SmartCoinOrderStatusExternal.IN_PROGRESS;
      break;
    case TransactionStatus.Completed:
      externalStatus = SmartCoinOrderStatusExternal.EXECUTED;
      break;
    case TransactionStatus.Failed:
      externalStatus = SmartCoinOrderStatusExternal.CANCELED;
      break;
  }
  return externalStatus;
};
