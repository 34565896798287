import { BaseModel } from '@core/base-model';

export interface BlockchainWalletQuery {
  id: string;
  balance: number;
  publicKey: string;
}

export class BlockchainWalletModel extends BaseModel<BlockchainWalletQuery> {
  private id: string;
  private balance: number;
  publicKey: string;

  constructor(data: BlockchainWalletQuery) {
    super();
    this.update(data);
  }

  get asJson(): BlockchainWalletQuery {
    return {
      id: this.id,
      balance: this.balance,
      publicKey: this.publicKey,
    };
  }
}
