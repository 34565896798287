import { BaseModel } from '@core/base-model';
import { OrderListQuery } from '@shared/models/orders/list-model';

export interface OrderBalancesListQuery {
  externalTransactionId: OrderListQuery['id'];
  balance: number;
  currency?: string;
}

export class OrderBalancesListModel extends BaseModel<OrderBalancesListQuery> {
  private externalTransactionId: OrderListQuery['id'];
  private balance: number;
  private currency: string;

  constructor(data: OrderBalancesListQuery) {
    super();
    this.update(data);
  }

  get asJson(): OrderBalancesListQuery {
    return {
      externalTransactionId: this.externalTransactionId,
      balance: this.balance,
      currency: this.currency,
    };
  }
}
