import { BaseModel } from '@core/base-model';
import { Currency } from '@shared/models/wallets/currency';

interface Value {
  value: number;
}

export interface PreciousMetalAvailableQuery {
  preciousMetal: string;
  price: Value;
  currency: Currency;
  audtPrice: Value;
  availableQuantity: number;
}

export class PreciousMetalAvailableModel extends BaseModel<PreciousMetalAvailableQuery> {
  private preciousMetal: string;
  private price: Value;
  private currency: Currency;
  private audtPrice: Value;
  private availableQuantity: number;

  constructor(data: PreciousMetalAvailableQuery) {
    super();
    this.update(data);
  }

  get asJson(): PreciousMetalAvailableQuery {
    return {
      preciousMetal: this.preciousMetal,
      price: this.price,
      currency: this.currency,
      audtPrice: this.audtPrice,
      availableQuantity: this.availableQuantity,
    };
  }
}
