import { BaseModel } from '@core/base-model';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';

export type DepositCreateError = {
  code: string;
  declineCode: string;
};

export interface DepositCreateQuery {
  transactionId: string;
  providerType: string;
  isSucceeded: boolean;
  requiresFurtherAction: boolean;
  code?: string;
  declineCode?: string;
  paymentReference?: null;
  response: ResponsePaymentResult;
  error?: DepositCreateError;
}

export class DepositCreateModel extends BaseModel<DepositCreateQuery> {
  transactionId: string;
  providerType: string;
  isSucceeded: boolean;
  requiresFurtherAction: boolean;
  code?: string;
  declineCode?: string;
  paymentReference?: null;
  response: ResponsePaymentResult;
  public error: DepositCreateError;

  constructor(data: DepositCreateQuery) {
    super();
    this.update(data);
  }

  get asJson(): DepositCreateQuery {
    return {
      transactionId: this.transactionId,
      providerType: this.providerType,
      code: this.code,
      declineCode: this.declineCode,
      paymentReference: this.paymentReference,
      response: this.response,
      isSucceeded: this.isSucceeded,
      requiresFurtherAction: this.requiresFurtherAction,
      error: this.error,
    };
  }
}
