import { BaseModel } from '@core/base-model';
import { MintingDetails } from '@shared/models/mintings/details';
import { OrderListQuery } from '@shared/models/orders/list-model';
import { OrderStatus } from '@shared/models/orders/order-status';
import { Currency } from '@shared/models/wallets/currency';

export interface OrderGetQuery {
  id: OrderListQuery['id'];
  status: OrderStatus;
  metalWeight: number;
  metalSpotPrice: number;
  price: number;
  currentPrice: number;
  currency: Currency;
  orderDate: string;
  plannedFulfillmentDate: string;
  actualFulfillmentDate: string;
  mintingDetails: MintingDetails[];
  senders: string[];
  receivers: string[];
  isPurchaser: boolean;
  unMintedWeight: number;
  userId: string;
}

export class OrderGetModel extends BaseModel<OrderGetQuery> {
  private id: OrderListQuery['id'];
  private status: OrderStatus;
  private metalWeight: number;
  private metalSpotPrice: number;
  private price: number;
  private currentPrice: number;
  private currency: Currency;
  private orderDate: string;
  private plannedFulfillmentDate: string;
  private actualFulfillmentDate: string;
  private mintingDetails: MintingDetails[];
  private senders: string[];
  private receivers: string[];
  private isPurchaser: boolean;
  private unMintedWeight: number;
  private userId: string;

  constructor(data: OrderGetQuery) {
    super();
    this.update(data);
  }

  get asJson(): OrderGetQuery {
    return {
      id: this.id,
      status: this.status,
      metalWeight: this.metalWeight,
      metalSpotPrice: this.metalSpotPrice,
      price: this.price,
      currentPrice: this.currentPrice,
      currency: this.currency,
      orderDate: this.orderDate,
      plannedFulfillmentDate: this.plannedFulfillmentDate,
      actualFulfillmentDate: this.actualFulfillmentDate,
      mintingDetails: this.mintingDetails,
      senders: this.senders,
      receivers: this.receivers,
      isPurchaser: this.isPurchaser,
      unMintedWeight: this.unMintedWeight,
      userId: this.userId,
    };
  }
}
