import { BaseModel } from '@core/base-model';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';

export type CreditCardListQuery = {
  id: string;
  last4: string;
  brand: string;
  fullBrandName: string;
  paymentProvider: string;
  isTemporary: boolean;
  isExpired: boolean;
  lowerLimit: number;
  upperLimit: number;
};

export class CreditCardListModel extends BaseModel<CreditCardListQuery> {
  private id: string;
  private last4: string;
  private brand: string;
  private fullBrandName: string;
  private paymentProvider: PaymentProviderEnum;
  private lowerLimit: number;
  private upperLimit: number;
  isTemporary: boolean;
  private isExpired: boolean;

  constructor(data: CreditCardListQuery) {
    super();
    this.update(data);
  }

  get asJson(): CreditCardListQuery {
    return {
      id: this.id,
      last4: this.last4,
      brand: this.brand,
      fullBrandName: this.fullBrandName,
      paymentProvider: this.paymentProvider,
      upperLimit: this.upperLimit,
      lowerLimit: this.lowerLimit,
      isTemporary: this.isTemporary,
      isExpired: this.isExpired,
    };
  }
}
