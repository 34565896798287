import { BaseModel } from '@core/base-model';

export interface GetContactListDTO {
  id: string;
  email: string;
  userStatus: string;
  avatarUrl: string;
  initials: string;
  systemColor: string;
  systemName: string;
}

export class GetContactListModel extends BaseModel<GetContactListDTO> {
  private id: string;
  private email: string;
  private userStatus: string;
  private avatarUrl: string;
  private initials: string;
  private systemColor: string;
  private systemName: string;

  constructor(data: GetContactListDTO) {
    super();
    this.update(data);
  }

  get asJson(): GetContactListDTO {
    return {
      id: this.id,
      email: this.email,
      userStatus: this.userStatus,
      avatarUrl: this.avatarUrl,
      initials: this.initials,
      systemColor: this.systemColor,
      systemName: this.systemName,
    };
  }
}
