import { BaseModel } from '@core/base-model';

export interface EmptyAvatarQuery {
  nameAbbreviation: string;
  color: string;
}

export class EmptyAvatarModel extends BaseModel<EmptyAvatarQuery> {
  nameAbbreviation: string;
  color: string;

  constructor(data: EmptyAvatarQuery) {
    super();
    this.update(data);
  }

  get asJson(): EmptyAvatarQuery {
    return {
      nameAbbreviation: this.nameAbbreviation,
      color: this.color,
    };
  }
}
