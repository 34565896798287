import { t } from '@lingui/macro';

export enum ErrorKeysEnum {
  User_Blocked = 'User_Blocked',
  Recipient_Blocked = 'Recipient_Blocked',
  User_Cannot_Be_Recipient = 'User_Cannot_Be_Recipient',
  User_Cannot_Request_Deletion = 'User_Cannot_Request_Deletion',
  User_Cannot_Make_Transaction = 'User_Cannot_Make_Transaction',
  User_Can_Be_Deleted_After_Approval = 'User_Can_Be_Deleted_After_Approval',
  Limited_Metal_Left = 'Limited_Metal_Left',
  User_Not_Verified = 'User_Not_Verified',
  Market_Out_Of_Work_Hours = 'Market_Out_Of_Work_Hours',
  Error = 'Error',
}

export interface HttpErrorResponseQuery {
  errors: any;
  correlationId?: string;
  status: number;
}

export class HttpErrorResponse {
  public errors: Map<ErrorKeysEnum, Array<string>>;
  public correlationId?: string;
  public status: number;

  static createHttpErrorResponse(data: any): HttpErrorResponse {
    return new HttpErrorResponse(data as HttpErrorResponseQuery);
  }

  constructor(data: HttpErrorResponseQuery) {
    this.update(data);
  }

  update(newData: HttpErrorResponseQuery) {
    this.correlationId = newData.correlationId;
    this.status = newData.status;
    if (!newData.errors || typeof newData.errors !== 'object') {
      newData.errors = {
        [ErrorKeysEnum.Error]: 'Unhandled error',
      };
    }
    this.errors = Object.keys(newData.errors).reduce((result, key) => {
      result.set(key as ErrorKeysEnum, newData.errors[key]);
      return result;
    }, new Map<ErrorKeysEnum, Array<string>>());
    return this;
  }

  findErrorMessageByKey(key: ErrorKeysEnum): string | null {
    const messageArray = this.errors.get(key);
    if (messageArray && typeof messageArray[0] === 'string') {
      return messageArray[0];
    } else {
      return null;
    }
  }

  findFirstErrorMessage(): string {
    const result: string | string[] | undefined = Array.from(this.errors.values())[0];
    let error = '';
    if (typeof result === 'string') {
      error = result;
    } else if (Array.isArray(result) && result[0]) {
      error = result[0];
    } else {
      error = t`Something went wrong`;
    }

    return error === 'Unhandled error' ? t`Something went wrong` : error;
  }

  hasError(errorKey: ErrorKeysEnum): boolean {
    return this.errors.has(errorKey);
  }
}
