import { BaseModel } from '@core/base-model';
import { UserListQuery } from '@shared/models/users/list-model';

export interface TransferListQuery {
  id: string;
  userId: UserListQuery['recipientUserId'];
  externalTransactionId: string;
  blockchainTransactionId: string;
  email: string;
  date: string;
  amount: number;
  status: string;
}

export class TransferListModel extends BaseModel<TransferListQuery> {
  private id: string;
  private userId: UserListQuery['recipientUserId'];
  private externalTransactionId: string;
  private blockchainTransactionId: string;
  private email: string;
  private date: string;
  private amount: number;
  private status: string;

  constructor(data: TransferListQuery) {
    super();
    this.update(data);
  }

  get asJson(): TransferListQuery {
    return {
      id: this.id,
      userId: this.userId,
      externalTransactionId: this.externalTransactionId,
      blockchainTransactionId: this.blockchainTransactionId,
      email: this.email,
      date: this.date,
      amount: this.amount,
      status: this.status,
    };
  }
}
