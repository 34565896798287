import { BaseModel } from '@core/base-model';

export interface AvatarImageQuery {
  id: string;
  url: string;
}

export class AvatarImageModel extends BaseModel<AvatarImageQuery> {
  id: string;
  url: string;

  constructor(data: AvatarImageQuery) {
    super();
    this.update(data);
  }

  get asJson(): AvatarImageQuery {
    return {
      id: this.id,
      url: this.url,
    };
  }
}
