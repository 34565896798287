import { BaseModel } from '@core/base-model';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import { Currency } from '@shared/models/wallets/currency';

export interface TransactionListQuery {
  id: string;
  cost: number;
  currency: Currency;
  date: string;
  status: TransactionStatus;
  transactionType: TransactionType;
  userEmail: string | null;
  value: number;
  blockchainTransactionId: string | null;
  externalTransactionId: string | null;
}

export class TransactionListModel extends BaseModel<TransactionListQuery> {
  private id: string;
  private cost: number;
  private currency: Currency;
  private date: string;
  private status: TransactionStatus;
  private transactionType: TransactionType;
  private userEmail: string | null;
  private value: number;
  private blockchainTransactionId: string | null;
  private externalTransactionId: string | null;

  constructor(data: TransactionListQuery) {
    super();
    this.update(data);
  }

  get asJson(): TransactionListQuery {
    return {
      id: this.id,
      cost: this.cost,
      currency: this.currency,
      date: this.date,
      status: this.status,
      transactionType: this.transactionType,
      userEmail: this.userEmail,
      value: this.value,
      blockchainTransactionId: this.blockchainTransactionId,
      externalTransactionId: this.externalTransactionId,
    };
  }
}
