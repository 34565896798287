import { BaseModel } from '@core/base-model';

export interface PagedResponseQuery<T> extends PagedQuery {
  data: T;
}

export interface PagedQuery {
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  totalPages: number;
}

export class PagedResponseModel<T> extends BaseModel<PagedResponseQuery<T>> {
  private data: T;
  private hasNext: boolean;
  private hasPrevious: boolean;
  private pageSize: number;
  private currentPage: number;
  private totalCount: number;
  private totalPages: number;

  constructor(params: PagedResponseQuery<T>) {
    super();
    this.update(params);
  }

  get list(): T {
    return this.data;
  }

  get pagination(): PagedQuery {
    return {
      hasNext: this.hasNext,
      hasPrevious: this.hasPrevious,
      pageSize: this.pageSize,
      currentPage: this.currentPage,
      totalCount: this.totalCount,
      totalPages: this.totalPages,
    };
  }

  get asJson(): PagedResponseQuery<T> {
    return {
      data: this.data,
      hasNext: this.hasNext,
      hasPrevious: this.hasPrevious,
      pageSize: this.pageSize,
      currentPage: this.currentPage,
      totalCount: this.totalCount,
      totalPages: this.totalPages,
    };
  }
}
