import { BaseModel } from '@core/base-model';

export type LimitDTO = {
  upperLimit: number;
  lowerLimit: number;
};

export interface LimitsDTO {
  fiatWallets: LimitDTO;
  creditCards: LimitDTO;
}

export class LimitsModel extends BaseModel<LimitsDTO> {
  private fiatWallets: LimitDTO;
  private creditCards: LimitDTO;

  get maxWalletLimit() {
    return this.fiatWallets.upperLimit;
  }

  get minWalletLimit() {
    return this.fiatWallets.lowerLimit;
  }

  get maxCardLimit() {
    return this.creditCards.upperLimit;
  }

  get minCardLimit() {
    return this.creditCards.lowerLimit;
  }

  constructor(data: LimitsDTO) {
    super();
    this.update(data);
  }

  get asJson(): LimitsDTO {
    return {
      fiatWallets: this.fiatWallets,
      creditCards: this.creditCards,
    };
  }
}
