import { BaseModel } from '@core/base-model';
import { SmartCoinOrderStatusInternal } from '@shared/types/smart-coin-order-status';

export interface SmartCoinOrderDetailsQuery {
  id: string;
  type: string;
  orderDate: string;
  quantity: number;
  smartCoin: string;
  salePrice: number;
  fiatCurrency: string;
  costOfPurchase: number;
  transactionFee: number;
  paymentMethod: string;
  paymentMethodBrand: string;
  paymentMethodSerialNumber: string;
  totalAmount: number;
  status: SmartCoinOrderStatusInternal;
  failureReason: string;
  blockchainTransactionId?: string;
}

export class SmartCoinOrderDetailsModel extends BaseModel<SmartCoinOrderDetailsQuery> {
  private id: string;
  private type: string;
  private orderDate: string;
  private quantity: number;
  private smartCoin: string;
  private salePrice: number;
  private fiatCurrency: string;
  private costOfPurchase: number;
  private transactionFee: number;
  private totalAmount: number;
  private status: SmartCoinOrderStatusInternal;
  private failureReason: string;
  private blockchainTransactionId: string;
  private paymentMethod: string;
  private paymentMethodBrand: string;
  private paymentMethodSerialNumber: string;

  constructor(data: SmartCoinOrderDetailsQuery) {
    super();
    this.update(data);
  }

  get asJson(): SmartCoinOrderDetailsQuery {
    return {
      id: this.id,
      type: this.type,
      orderDate: this.orderDate,
      quantity: this.quantity,
      smartCoin: this.smartCoin,
      salePrice: this.salePrice,
      fiatCurrency: this.fiatCurrency,
      costOfPurchase: this.costOfPurchase,
      transactionFee: this.transactionFee,
      totalAmount: this.totalAmount,
      status: this.status,
      paymentMethod: this.paymentMethod,
      paymentMethodSerialNumber: this.paymentMethodSerialNumber,
      paymentMethodBrand: this.paymentMethodBrand,
      failureReason: this.failureReason,
      blockchainTransactionId: this.blockchainTransactionId,
    };
  }
}
