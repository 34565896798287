export const amountPipe = (amount: number, precision: number = 2): string => {
  return Intl.NumberFormat('en-EN', { maximumFractionDigits: precision }).format(amount);
};

export const minDigitsAfterDot = (value: string, precision: number = 2): string => {
  const [intPart, floatPart] = value.split('.');
  return `${intPart}.${(floatPart || '').padEnd(precision, '0')}`;
};

export const spotPriceAmountPipe = (amount: number): string => {
  return new Intl.NumberFormat('en-EN').format(amount);
};
