export enum TransactionType {
  PURCHASED = 'Purchased',
  SOLD = 'Sold',
  SENT = 'Sent',
  RECEIVED = 'Received',
  DEPOSIT = 'Deposit',
  SPENT = 'Spent',
  EARNED = 'Earned',
  WITHDRAWAL = 'Withdrawal',
}
