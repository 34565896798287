import { BaseModel } from '@core/base-model';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';

export interface TransacrtionDetailsDTO {
  id: string;
  date: string;
  status: TransactionStatus;
  type: string;
  value: number;
  currency: string;
  transactionFee: number;
  baseCurrency: string;
  userId: string;
  userEmail: string;
  blockchainTransactionId: string;
  failureCode: string;
  failureMessage: string;
  paymentMethod: string;
  paymentMethodBrand: string;
  paymentMethodSerialNumber: string;
}

export class TransacrtionDetailsModel extends BaseModel<TransacrtionDetailsDTO> {
  private id: string;
  private date: string;
  private status: TransactionStatus;
  private type: string;
  private value: number;
  private currency: string;
  private transactionFee: number;
  private baseCurrency: string;
  private userId: string;
  private userEmail: string;
  private blockchainTransactionId: string;
  private failureCode: string;
  private failureMessage: string;
  private paymentMethod: string;
  private paymentMethodBrand: string;
  private paymentMethodSerialNumber: string;

  constructor(data: TransacrtionDetailsDTO) {
    super();
    this.update(data);
  }

  get asJson(): TransacrtionDetailsDTO {
    return {
      id: this.id,
      date: this.date,
      status: this.status,
      type: this.type,
      value: this.value,
      currency: this.currency,
      transactionFee: this.transactionFee,
      baseCurrency: this.baseCurrency,
      userId: this.userId,
      userEmail: this.userEmail,
      blockchainTransactionId: this.blockchainTransactionId,
      failureCode: this.failureCode,
      failureMessage: this.failureMessage,
      paymentMethod: this.paymentMethod,
      paymentMethodSerialNumber: this.paymentMethodSerialNumber,
      paymentMethodBrand: this.paymentMethodBrand,
    };
  }
}
