import { BaseModel } from '@core/base-model';
import { UserRestrictionReasonsEnum } from '@shared/enums/user-restriction-reasons.enum';
import { UserStatus } from '@shared/models/users/user-status';

export interface UserSettings {
  EmailNotification: boolean;
  PushNotification: boolean;
}

export interface UserDetailsDTO {
  id: string;
  name: string;
  alterName?: string;
  systemName?: string;
  email: string;
  userStatus: string;
  phone: string;
  citizenshipCountry: string;
  citizenshipCity: string;
  zipCode: string;
  address: string;
  userSettings?: UserSettings;
  avatarUrl?: string;
  systemColor?: string;
  userType?: string;
  approvedDocumentIds?: Array<string>;
  initials: string;
}

export class UserDetailsModel extends BaseModel<UserDetailsDTO> {
  private id: string;
  private name: string;
  private alterName: string;
  private email: string;
  private userStatus: string;
  private phone: string;
  private citizenshipCountry: string;
  private citizenshipCity: string;
  private zipCode: string;
  private address: string;
  private userSettings?: UserSettings;
  private systemName?: string;
  private avatarUrl?: string;
  private systemColor?: string;
  private userType?: string;
  private approvedDocumentIds?: Array<string>;
  private initials: string;

  constructor(data: UserDetailsDTO) {
    super();
    this.update(data);
  }

  get restrictions(): UserRestrictionReasonsEnum | null {
    let restriction = null;
    switch (this.userStatus) {
      case UserStatus.DeletionRequested:
        restriction = UserRestrictionReasonsEnum.DELETION_REQUEST;
        break;
      case UserStatus.Blocked:
        restriction = UserRestrictionReasonsEnum.BLOCKED;
        break;
      default:
        break;
    }
    return restriction;
  }

  get asJson(): UserDetailsDTO {
    return {
      id: this.id || '',
      name: this.name || '',
      alterName: this.alterName || '',
      email: this.email || '',
      userStatus: this.userStatus || '',
      phone: this.phone || '',
      citizenshipCountry: this.citizenshipCountry || '',
      citizenshipCity: this.citizenshipCity || '',
      zipCode: this.zipCode || '',
      address: this.address || '',
      userSettings: this.userSettings,
      systemName: this.systemName || '',
      avatarUrl: this.avatarUrl || '',
      systemColor: this.systemColor || '',
      userType: this.userType || '',
      approvedDocumentIds: this.approvedDocumentIds || [],
      initials: this.initials,
    };
  }
}
