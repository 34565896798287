import { BaseModel } from '@core/base-model';
import { TransactionType } from '@shared/models/transactions/transaction-type';

export interface RecentActivityQuery {
  date: string;
  transactionType: TransactionType;
  value: number;
  currency: string;
}

export class RecentActivitiesModel extends BaseModel<RecentActivityQuery> {
  private date: string;
  private transactionType: TransactionType;
  private value: number;
  private currency: string;

  constructor(data: RecentActivityQuery) {
    super();
    this.update(data);
  }

  get asJson(): RecentActivityQuery {
    return {
      date: this.date,
      transactionType: this.transactionType,
      value: this.value,
      currency: this.currency,
    };
  }
}
