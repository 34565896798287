import { BaseModel } from '@core/base-model';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';

import { PaymentResultQuery } from './payment-result-model';

export interface SmartCoinOrderStatusQuery {
  status: string;
  paymentResult: PaymentResultQuery;
  declineCode: string;
  declineMessage: string;
  smartCoin: string;
  quantity: number;
  type: SmartCoinOrderType;
}

export class SmartCoinOrderStatusModel extends BaseModel<SmartCoinOrderStatusQuery> {
  private status: string;
  paymentResult: PaymentResultQuery;
  private declineCode: string;
  private declineMessage: string;
  private smartCoin: string;
  private quantity: number;
  private type: SmartCoinOrderType;

  constructor(data: SmartCoinOrderStatusQuery) {
    super();
    this.update(data);
  }

  get asJson(): SmartCoinOrderStatusQuery {
    return {
      status: this.status,
      paymentResult: this.paymentResult,
      declineCode: this.declineCode,
      declineMessage: this.declineMessage,
      smartCoin: this.smartCoin,
      quantity: this.quantity,
      type: this.type,
    };
  }
}
