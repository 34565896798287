import { BaseModel } from '@core/base-model';

export interface KYCCredentialsQuery {
  token: string;
  kycUserId: string;
}

export class KYCCredentialsModel extends BaseModel<KYCCredentialsQuery> {
  private token: string;
  private kycUserId: string;

  constructor(data: KYCCredentialsQuery) {
    super();
    this.update(data);
  }

  get asJson(): KYCCredentialsQuery {
    return {
      token: this.token,
      kycUserId: this.kycUserId,
    };
  }
}
