import { BaseModel } from '@core/base-model';

export interface ReportPortfolioDTO {
  investmentsRatio: any;
  historicData: Array<ReportPortfolioItemDTO>;
}

export class ReportPortfolioModel extends BaseModel<ReportPortfolioDTO> {
  private investmentsRatio: any;
  private historicData: Array<ReportPortfolioItemDTO>;

  constructor(data: ReportPortfolioDTO) {
    super();
    this.update(data);
    this.investmentsRatio = this.roundInvestmentsRatio(data.investmentsRatio);
  }

  get asJson(): ReportPortfolioDTO {
    return {
      investmentsRatio: this.investmentsRatio,
      historicData: this.historicData,
    };
  }

  roundInvestmentsRatio(data: any) {
    if (Object.values(data).every((val) => val === 0)) {
      return data;
    }

    const currencies: Array<string> = Object.keys(data);
    const roundedMap: Map<string, number> = new Map<string, number>();

    let total = 0;
    currencies.forEach((currency: string) => {
      roundedMap.set(currency, Math.round(data[currency] * 10) / 10);
      total += roundedMap.get(currency) || 0;
    });

    let difference = 100 - total;

    if (difference !== 0 && currencies.length > 0) {
      const findTheGreatestCurrency = (
        currencies: Array<string>,
        roundedMap: Map<string, number>,
      ) => {
        const data = currencies.reduce(
          (data, currency, index) => {
            const currenctValue = roundedMap.get(currency) || 0;
            if (currenctValue > data.value) {
              data.value = currenctValue;
              data.index = index;
              data.currency = currency;
            }
            return data;
          },
          { value: 0, index: 0, currency: '' },
        );
        return data.currency;
      };

      const greatestCurrency = findTheGreatestCurrency(currencies, roundedMap);
      roundedMap.set(
        greatestCurrency || '',
        Math.round(((roundedMap.get(greatestCurrency || '') || 0) + difference) * 10) / 10,
      );
    }

    return Object.keys(data).reduce((acc, currency) => {
      acc[currency] = roundedMap.get(currency) || 0;
      return acc;
    }, {} as any);
  }
}

export interface ReportPortfolioItemDTO {
  date: string;
  value: number;
  valueInBaseCurrency: number;
}

export class ReportPortfolioItemModel extends BaseModel<ReportPortfolioItemDTO> {
  private date: string;
  private value: number;
  private valueInBaseCurrency: number;

  constructor(data: ReportPortfolioItemDTO) {
    super();
    this.update(data);
  }

  get asJson(): ReportPortfolioItemDTO {
    return {
      date: this.date,
      value: this.value,
      valueInBaseCurrency: this.valueInBaseCurrency,
    };
  }
}
