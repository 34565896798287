import { BaseModel } from '@core/base-model';

export interface MetalProductSpotPriceDTO {
  id: string;
  productName: string;
  metalName: string;
  price: number;
  priceChangePercent: number;
  iconUrl: string;
}

export class MetalProductSpotPriceModel extends BaseModel<MetalProductSpotPriceDTO> {
  private id: string;
  private productName: string;
  private metalName: string;
  private price: number;
  private priceChangePercent: number;
  private iconUrl: string;

  constructor(data: MetalProductSpotPriceDTO) {
    super();
    this.update(data);
  }

  get asJson(): MetalProductSpotPriceDTO {
    return {
      id: this.id,
      productName: this.productName,
      metalName: this.metalName,
      price: this.price,
      priceChangePercent: this.priceChangePercent,
      iconUrl: this.iconUrl,
    };
  }
}
