import { BaseModel } from '@core/base-model';

export interface ReportRoiListQuery {
  date: string;
  currentRoi: number;
  cumulativeRoi: number;
  absoluteCurrentRoi: number;
  absoluteCumulativeRoi: number;
}

export class ReportRoiListModel extends BaseModel<ReportRoiListQuery> {
  private date: string;
  private currentRoi: number;
  private cumulativeRoi: number;
  private absoluteCurrentRoi: number;
  private absoluteCumulativeRoi: number;

  constructor(data: ReportRoiListQuery) {
    super();
    this.update(data);
  }

  get asJson(): ReportRoiListQuery {
    return {
      date: this.date,
      currentRoi: this.currentRoi,
      cumulativeRoi: this.cumulativeRoi,
      absoluteCurrentRoi: this.absoluteCurrentRoi,
      absoluteCumulativeRoi: this.absoluteCumulativeRoi,
    };
  }
}
