import { BaseModel } from '@core/base-model';
import { SmartCoinOrderStatusInternal } from '@shared/types/smart-coin-order-status';

export interface SmartCoinOrderListQuery {
  id: string;
  orderDate: string;
  quantity: number;
  smartCoin: string;
  salePrice: number;
  fiatCurrency: string;
  costOfPurchase: number;
  transactionFee: number;
  totalAmount: number;
  status: SmartCoinOrderStatusInternal;
  type: string;
}

export class SmartCoinOrderListModel extends BaseModel<SmartCoinOrderListQuery> {
  private id: string;
  private orderDate: string;
  private quantity: number;
  private smartCoin: string;
  private salePrice: number;
  private fiatCurrency: string;
  private costOfPurchase: number;
  private transactionFee: number;
  private totalAmount: number;
  private status: SmartCoinOrderStatusInternal;
  private type: string;

  constructor(data: SmartCoinOrderListQuery) {
    super();
    this.update(data);
  }

  get asJson(): SmartCoinOrderListQuery {
    return {
      id: this.id,
      orderDate: this.orderDate,
      quantity: this.quantity,
      smartCoin: this.smartCoin,
      salePrice: this.salePrice,
      fiatCurrency: this.fiatCurrency,
      costOfPurchase: this.costOfPurchase,
      transactionFee: this.transactionFee,
      totalAmount: this.totalAmount,
      status: this.status,
      type: this.type,
    };
  }
}
